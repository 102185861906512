import React, { useEffect, useState } from 'react';
import { AmazonLogo, SearsLogo } from './Logos';
import { darker, lighter } from '../../helpers/functions';
import { Link } from 'react-router-dom';


export const GiftCards = ({ dev, cards, MainColor, theme, font, colorPalette }) => {

    const [card, setCard] = useState(cards)

    const handleLogos = (type) => {
        switch (type.toUpperCase()) {
            case 'AMAZON':
                return <AmazonLogo width={'50%'} height={'50%'} MainColor={colorPalette.accent} theme={theme} />
            case 'SEARS':
                return <SearsLogo width={'50%'} height={'50%'} MainColor={colorPalette.accent} theme={theme} />

            default: return <span style={{
                color: colorPalette.accent,
                fontFamily: font
            }}>{type}</span>;

        }
    }

    useEffect(() => {
        setCard(cards)
    }, [cards])


    return (

        <>
            {card ?
                card.map((item, index) => (
                    <div
                        key={index}
                        className="gift-card-container"
                        style={{
                            background: `${colorPalette.secondary}`
                        }}
                    >
                        <a
                            href={item.url}
                            target='_blank'
                            rel='noopener noreferrer'
                            className={item.link ? "gift-card-page" : "gift-card-bank"}
                            style={{
                                color: colorPalette.accent,
                                cursor: item.link ? 'pointer' : 'default',
                            }}
                        >
                            {item.link ? (
                                handleLogos(item.type)
                            ) : (
                                <>
                                    <span
                                        className={!dev ? "g-mdoule-regular-text" : "g-mdoule-regular-text-dev"}
                                        style={{
                                            fontFamily: font,
                                            color: colorPalette.accent,
                                            fontSize: '18px'

                                        }}
                                    >
                                        <b>{item.bank}</b>
                                    </span>
                                    <div className='gifts-single-col'>
                                        <span
                                            className={!dev ? "g-mdoule-regular-text" : "g-mdoule-regular-text-dev"}
                                            style={{
                                                fontFamily: font,
                                                color: colorPalette.accent,
                                            }}
                                        >
                                            {item.name}
                                        </span>
                                        <span
                                            className={!dev ? "g-mdoule-regular-text" : "g-mdoule-regular-text-dev"}
                                            style={{
                                                fontFamily: font,
                                                color: colorPalette.accent,
                                            }}
                                        >
                                            <b>{item.number}</b>
                                        </span>
                                    </div>

                                </>
                            )}
                        </a>
                    </div>
                ))
                : <></>
            }
        </>

    );
};
