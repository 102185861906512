import { Button, Input, Layout } from 'antd'
import React, { useEffect, useState } from 'react'
import GuestsPage from './GuestsPage'
import { HeaderShared } from '../layouts/Build'
import { FaLock } from 'react-icons/fa'
import { loginShare } from '../services/apiGuests'
import { useInvitation } from '../hooks'
import { BuildCard } from './BuildInvitation/BuildCard'
import { getInvitationbyID } from '../services'
import { QRHandler } from '../components/guests/QRHandler'

export const ShareGuests = ({ invitationID }) => {

    // const [mode, setMode] = useState(false)
    const [mode, setMode] = useState('on-edit')
    const [validated, setValidated] = useState(false)
    const [access, setAccess] = useState(null)
    const [usuario, setUsuario] = useState(null)
    const [saved, setSaved] = useState(true)
    const { response, operation } = useInvitation()
    const [invitation, setInvitation] = useState(null)
    const [loader, setLoader] = useState(false)
    const [content, setContent] = useState(null)
    const [generals, setGenerals] = useState(null)
    const [label, setLabel] = useState(null)
    const [onQR, setOnQR] = useState(false)
    const [visible, setVisible] = useState(false)
    const [begin, setBegin] = useState(false)


    const handleLogin = () => {
        loginShare(operation, invitationID, access)
    }

    useEffect(() => {
        if (mode === 'on-guests') {
            setSaved(true)
        }
    }, [mode])

    useEffect(() => {
        if (response) {

            if (response.data.ok) {
                switch (response.data.msg) {
                    case "Valid user":
                        setValidated(true)
                        console.log('usuario: ', response.data.data.user)
                        setUsuario(response.data.data.user)
                        const token = response.data.data.token
                        localStorage.setItem("token", token)
                        break;

                    case "Get invitation By Id":
                        const inv = response.data.data
                        setInvitation(inv)
                        setLoader(false)

                        if (begin) {
                            setContent(inv.cover)
                            setGenerals(inv.generals)
                            setLabel(inv.label)
                            setVisible(true)
                            setOnQR(false)
                            setBegin(false)
                        }


                        break;
                    default:
                        break;
                }



            }
        }
    }, [response])

    useEffect(() => {
        if (onQR) {
            setBegin(true)
            getInvitationbyID(operation, invitationID)
        }
    }, [onQR])


    useEffect(() => {
        setLoader(true)
        getInvitationbyID(operation, invitationID)
    }, [])


    const handleModule = (type) => {
        switch (type) {

            case 'on-edit':
                return <BuildCard mode={mode} setMode={setMode} invitationID={invitationID} saved={saved} setSaved={setSaved} shared={true} setOnQR={setOnQR} />

            case 'on-guests':
                return <GuestsPage mode={mode} setMode={setMode} invitationID={invitationID} shared={true} saved={saved} invitation={invitation} setOnQR={setOnQR} />

            default:
                break;
        }
    }

    // const [mode, setMode] = useState()

    return (


        <>
            {
                !validated ?
                    <Layout style={{
                        width: '100%', height: '100vh'
                    }}>
                        <div className='locked-invitation-background'>
                            <div className='locked-invitation-container'>
                                <div className='locked-icon-container'>
                                    <FaLock />
                                </div>
                                <h1 className='locked-page-title'>Acceso a Panel de Administración de Invitaciones</h1>
                                <h2 className='locked-page-text'>Para acceder al panel de respuestas, ingrese su usuario y contraseña. Esto mantiene la seguridad y privacidad del evento. </h2>
                                <Input
                                    value={access}
                                    onChange={(e) => setAccess(e.target.value)}
                                    placeholder='Código de acceso' className='locked-input' />
                                <Button
                                    onClick={handleLogin}
                                    id="locked-access-button" >Continuar</Button>
                            </div>


                        </div>
                    </Layout>
                    :

                    handleModule(mode)


            }

            {
                content && (
                    <QRHandler visible={visible} setVisible={setVisible} content={content} generals={generals} label={label} inv={invitation} />
                )
            }
        </>

    )
}
