import React, { useContext, useEffect, useState, } from 'react'
import { Button, Layout, Select } from 'antd';
import { InvitationTest } from '../../pages';
import { appContext } from '../../context';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
import ios_settings from '../../assets/iphone-settings.svg'
import android_settings from '../../assets/android-settings.png'


const { Content } = Layout;
const { Option } = Select;

const screens = [
    // {
    //     name: 'iPhone 15 & 15 Pro MAX',
    //     width: '430px',
    //     height: '932px'
    // },

    {
        name: 'iPhone',
        width: '340px',
        height: '80vh'
    },

    {
        name: 'iPad',
        width: '900px',
        height: '80vh'
    },

];

export const ContentBuild = ({ positionY, setPositionY, invitation, coverUpdated, onTry, currentDevice }) => {

    const { setOnDesigning } = useContext(appContext)
    const [size, setSize] = useState({ width: 'calc(100vh / 2.5)', height: '668px' });
    // const [device, setDevice] = useState('ios')

    const handleResize = (event, { size }) => {
        setSize(size);
    };



    useEffect(() => {
        setPositionY(positionY)
    }, [coverUpdated])

    useEffect(() => {
        if (currentDevice === 'ios') {
            setSize({ width: null, height: '668px' })
        } else {
            setSize({ width: null, height: '679px' })
        }

    }, [currentDevice])


    {/* <InvitationTest positionY={positionY} invitation={invitation} size={size} /> */ }

    return (

        invitation && positionY && !coverUpdated ?
            <div className='device-container'
                style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    flexDirection: 'column',
                    width: `auto`,
                    padding: '10px 30px',
                    position: 'relative',
                    flex: 1,
                }}
            >
                {/* <div style={{
                    position: 'absolute', top: '30px', right: '50px',
                    display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px'
                }}>
                    <Button onClick={() => setDevice('ios')}>iOS</Button>
                    <Button onClick={() => setDevice('android')}>Andorid</Button>
                </div> */}

                <div className={`inv-device-main-container-${currentDevice}`}>
                    <div className={`device-buttons-container-${currentDevice}`}>
                        <div className={`device-button-${currentDevice}`} />
                        <div className={`device-button-${currentDevice}`} />
                        <div className={`device-button-${currentDevice}`} />
                    </div>
                    <div className={`device-power-button-${currentDevice}`} />
                    <div className={`inv-device-container-${currentDevice}`}>

                        <div className={`inv-black-space-${currentDevice}`}>
                            <span>5:15</span>
                            <div className={`camera-${currentDevice}`} />
                            <div>
                                <img src={currentDevice === 'ios' ? ios_settings : android_settings} style={{
                                    height: '100%', objectFit: 'cover'
                                }} />
                            </div>
                        </div>

                        <div className={`scroll-invitation ${currentDevice}-invitation`}>
                            <InvitationTest positionY={positionY} invitation={invitation} size={size} />
                        </div>
                        <div className={`inv-light-space-${currentDevice}`} />
                    </div>
                </div>


            </div >
            : <></>


    )
}
