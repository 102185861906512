import { Button, Col, Input, Row, Switch } from 'antd'


import { BiSolidColorFill, BsStars, LuSeparatorHorizontal, TbEyeClosed, greetingsAI } from '../../helpers';
import { appContext } from '../../context';
import { useContext, useEffect, useState } from 'react';

export const BuildGreeting = ({ invitation, setInvitation, setSaved, onDesign }) => {

    const [onButton, setOnButton] = useState(false)
    const [onGeneration, setOnGeneration] = useState(false)
    const [titleValue, setTitleValue] = useState(null)
    const [descriptionValue, setDescriptionValue] = useState(null)
    const [textAppear, setTextAppear] = useState(false)



    const handleGenerating = () => {
        const getRandomGreeting = (label) => {
            const greetingsMap = {
                wedding: greetingsAI.weeding,
                xv: greetingsAI.xv,
                kids: greetingsAI.kids,
                bap: greetingsAI.bap,
                event: greetingsAI.event,
                party: greetingsAI.party,
            };

            const selectedGreeting = greetingsMap[label];
            if (selectedGreeting) {
                const title = selectedGreeting.titles[Math.floor(Math.random() * 9)];
                const description = selectedGreeting.texts[Math.floor(Math.random() * 9)];
                return { title, description };
            }
            return { title: '', description: '' };
        };

        const { title: local_title, description: local_description } = getRandomGreeting(invitation.label);

        // const typeText = (text, setValue, delay = 150) => {
        //     const words = text.split(' ');
        //     let index = 0;

        //     const displayWords = () => {
        //         if (index < words.length) {
        //             setValue(prev => prev + (prev ? ' ' : '') + words[index]);
        //             index++;
        //             setTimeout(displayWords, delay);
        //         }
        //     };

        //     setValue('');
        //     setTimeout(displayWords, delay);
        // };



        setTitleValue('Generando ...');
        setDescriptionValue('Generando ...');

        setTimeout(() => {
            setTitleValue(local_title)
            // typeText(local_title, setTitleValue);
            setTimeout(() => {
                setDescriptionValue(local_description)
                // typeDescription(local_description, setDescriptionValue);
            }, 500);

        }, 4500);

        setOnGeneration(true);

        setTimeout(() => {
            setInvitation(prevInvitation => ({
                ...prevInvitation,
                greeting: {
                    ...prevInvitation.greeting,
                    title: local_title,
                    description: local_description,
                },
            }));
            setSaved(false);
            setOnGeneration(false);
        }, 5000);
    };

    const onChangeTitle = (e) => {
        setTitleValue(e.target.value)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            greeting: {
                ...prevInvitation.greeting,
                title: e ? e.target.value : prevInvitation.greeting.title,
            },
        }));
        setSaved(false)
    }

    const onChangeDescription = (e) => {
        setDescriptionValue(e.target.value)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            greeting: {
                ...prevInvitation.greeting,
                description: e ? e.target.value : prevInvitation.greeting.description,
            },
        }));
        setSaved(false)
    }

    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            greeting: {
                ...prevInvitation.greeting,
                active: e,
            },
        }));
        setSaved(false)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            greeting: {
                ...prevInvitation.greeting,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            greeting: {
                ...prevInvitation.greeting,
                separator: e,
            },
        }));
        setSaved(false)
    }

    useEffect(() => {
        setTitleValue(invitation.greeting.title)
        setDescriptionValue(invitation.greeting.description)
    }, [])

    useEffect(() => {

        if (onButton) {
            setTimeout(() => {
                setTextAppear(onButton)
            }, 350);

        } else {
            setTextAppear(onButton)
        }

    }, [onButton])



    return (
        <>
            {
                invitation ?
                    <div className='scroll-item generals-main-container'>
                        <div className='build-component-elements'>

                            <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                                <div className='general-cards-single-row'>
                                    <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                                        style={{
                                            width: 'auto',
                                        }}
                                    >Bienvenida</span>
                                    <Switch
                                        size='small'
                                        value={invitation.greeting.active}
                                        onChange={handleActive} />
                                </div>


                                <div className='general-cards-single-row' style={{ gap: '5px' }}>
                                    {
                                        invitation.greeting.active && (

                                            <>
                                                <Button
                                                    onMouseEnter={() => setOnButton(true)}
                                                    onMouseLeave={() => setOnButton(false)}
                                                    type='ghost'
                                                    onClick={handleGenerating}
                                                    id={`gc-cta-magic-btn`}
                                                    style={{
                                                        width: '35px',
                                                        transition: 'all 0.4s ease',
                                                        boxShadow: onButton ? '0 0 15px #FFB32D' : '',
                                                        borderRadius: '8px',
                                                        height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                        background: onButton ? 'linear-gradient(to bottom, #FFB32D, #fdc564)' : 'transparent',
                                                        color: onButton ? '#FFF' : '#000',


                                                    }}
                                                    icon={<BsStars size={20} />}>

                                                </Button>

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeSeparator(!invitation.greeting.separator)}
                                                    id={`build-cover-date-buttons${invitation.greeting.separator ? '--active' : ''}`}
                                                    icon={<LuSeparatorHorizontal size={18} />} />

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeBackground(!invitation.greeting.background)}
                                                    id={`build-cover-date-buttons${invitation.greeting.background ? '--active' : ''}`}
                                                    icon={<BiSolidColorFill size={18} />} />
                                            </>
                                        )
                                    }
                                </div>

                            </div>


                            {
                                invitation.greeting.active ?

                                    <>
                                        <div className='build-generals-simple-column'>
                                            <span className='gc-content-label'>Título</span>

                                            <Input
                                                onChange={onChangeTitle}
                                                value={titleValue}
                                                style={{ width: '100%', transition: 'all 0.3s ease' }}
                                                className={`gc-input-text ${onGeneration ? 'magic-effect' : ''}`} />
                                        </div>



                                        <div className='build-generals-simple-column'>
                                            <span className='gc-content-label'>Descripción</span>
                                            <Input.TextArea
                                                value={descriptionValue}
                                                onChange={onChangeDescription}
                                                autoSize={{ minRows: 10, maxRows: 20 }}
                                                className={`gc-input-text ${onGeneration ? 'magic-effect' : ''}`}
                                                style={{ borderRadius: '12px', minWidth: '290px', transition: 'all 0.3s ease' }} />
                                        </div>
                                    </>


                                    : <div style={{
                                        width: '100%', height: '300px',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                                    }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>
                            }

                        </div>
                    </div>
                    : <></>
            }
        </>
    )
}




