import { Button, Col, Input, Row, Switch } from 'antd'

import { IoMdAdd, LuSeparatorHorizontal, IoClose, BiSolidColorFill, TbEyeClosed, giftsAI, BsStars, RiDeleteBack2Line, IoIosArrowDown } from '../../helpers';
import { CustomButton } from '../../components/CustomButton';
import { useContext, useEffect, useState } from 'react';
import { appContext } from '../../context';


export const BuildGifts = ({ invitation, setInvitation, setSaved, onDesign }) => {

    const [onButton, setOnButton] = useState(false)
    const [onGeneration, setOnGeneration] = useState(false)
    const [descriptionValue, setDescriptionValue] = useState(null)
    const [textAppear, setTextAppear] = useState(false)
    const [currentCard, setCurrentCard] = useState(null)

    const handleGenerating = () => {

        let local_description = giftsAI[Math.floor(Math.random() * 9)]

        setDescriptionValue('Generando ...');

        setTimeout(() => {
            setDescriptionValue(local_description)
        }, 4500);

        setOnGeneration(true);

        setTimeout(() => {
            setInvitation(prevInvitation => ({
                ...prevInvitation,
                gifts: {
                    ...prevInvitation.gifts,
                    description: local_description,
                },
            }));
            setSaved(false);
            setOnGeneration(false);
        }, 5000);
    };

    const onChangeTitle = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                title: e ? e.target.value : prevInvitation.gifts.title,
            },
        }));
        setSaved(false)
    }

    const onChangeDescription = (e) => {
        setDescriptionValue(e.target.value)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                description: e ? e.target.value : prevInvitation.gifts.description,
            },
        }));
        setSaved(false)
    }

    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                active: e,
            },
        }));
        setSaved(false)
    }

    const deleteCardByIndex = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.filter((card, i) => i !== index)
            }
        }));
        setSaved(false)
    }

    const addNewCard = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: [
                    ...prevInvitation.gifts.cards,
                    {
                        link: true,
                        type: 'Amazon',
                        url: "https://www.amazon.com.mx/",
                        bank: null,
                        name: null,
                        number: null
                    }
                ]
            }
        }));
        setSaved(false)
    }

    const toggleCardState = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.map((card, i) => {
                    if (i === index) {
                        if (!card.link) {
                            return {
                                ...card,
                                link: true,
                                type: "Nombre de la página",
                                url: "URL",
                                bank: null,
                                name: null,
                                number: null
                            };
                        } else {
                            return {
                                ...card,
                                link: false,
                                type: null,
                                url: null,
                                bank: "Banco",
                                name: "Nombre",
                                number: "Número de cuenta"
                            };
                        }
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const changeCardTypeByIndex = (index, newType) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            type: newType
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const changeCardUrlByIndex = (index, newUrl) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            url: newUrl
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const changeCardBankByIndex = (index, newBank) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            bank: newBank
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const changeCardNameByIndex = (index, newName) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            name: newName
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const changeCardNumberByIndex = (index, newNumber) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            number: newNumber
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                separator: e,
            },
        }));
        setSaved(false)
    }

    useEffect(() => {
        setDescriptionValue(invitation.gifts.description)
    }, [])

    useEffect(() => {

        if (onButton) {
            setTimeout(() => {
                setTextAppear(onButton)
            }, 350);

        } else {
            setTextAppear(onButton)
        }

    }, [onButton])

    return (
        <>
            {
                invitation ?

                    <div className='scroll-item generals-main-container'>
                        <div className='build-component-elements'>
                            <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                                <div className='general-cards-single-row'>
                                    <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                                        style={{
                                            width: 'auto',
                                        }}
                                    >Mesa de regalos</span>
                                    <Switch
                                        size='small'
                                        value={invitation.gifts.active}
                                        onChange={handleActive} />
                                </div>


                                <div className='general-cards-single-row' style={{ gap: '5px' }}>
                                    {
                                        invitation.gifts.active && (

                                            <>
                                                <Button
                                                    onMouseEnter={() => setOnButton(true)}
                                                    onMouseLeave={() => setOnButton(false)}
                                                    type='ghost'
                                                    onClick={handleGenerating}
                                                    id={`gc-cta-magic-btn`}
                                                    style={{
                                                        width: '35px',
                                                        transition: 'all 0.4s ease',
                                                        boxShadow: onButton ? '0 0 15px #FFB32D' : '',
                                                        borderRadius: '8px',
                                                        height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                        background: onButton ? 'linear-gradient(to bottom, #FFB32D, #fdc564)' : 'transparent',
                                                        color: onButton ? '#FFF' : '#000',


                                                    }}
                                                    icon={<BsStars size={20} />}>

                                                </Button>

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeSeparator(!invitation.gifts.separator)}
                                                    id={`build-cover-date-buttons${invitation.gifts.separator ? '--active' : ''}`}
                                                    icon={<LuSeparatorHorizontal size={18} />} />

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeBackground(!invitation.gifts.background)}
                                                    id={`build-cover-date-buttons${invitation.gifts.background ? '--active' : ''}`}
                                                    icon={<BiSolidColorFill size={18} />} />
                                            </>
                                        )
                                    }
                                </div>

                            </div>
                            {
                                invitation.gifts.active ?

                                    <>
                                        <span className='gc-content-label'>Título</span>
                                        <Input className='gc-input-text'
                                            onChange={onChangeTitle}
                                            value={invitation.gifts.title} />

                                        <span className='gc-content-label'>Descripción</span>
                                        <Input.TextArea className={`gc-input-text ${onGeneration ? 'magic-effect' : ''}`}
                                            style={{ borderRadius: '8px' }}
                                            value={descriptionValue}
                                            onChange={onChangeDescription}
                                            autoSize={{ minRows: 8, maxRows: 15 }} />

                                    </>


                                    : <div />
                            }
                        </div>

                        {
                            invitation.gifts.active ?

                                <div className='build-component-elements'>
                                    <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                                        style={{
                                            width: 'auto',
                                        }}
                                    >Mis tarjetas</span>

                                    <CustomButton
                                        onClick={addNewCard}
                                        icon={<IoMdAdd />} text={'Nueva Tarjeta'} style={{
                                            margin: '10px 0'
                                        }} />

                                    {
                                        invitation.gifts.cards ?
                                            invitation.gifts.cards.map((card, index) => (

                                                <div
                                                    className='regular-card'
                                                    style={{
                                                        width: !onDesign && '100%', padding: currentCard !== index ? '8px' : '12px',
                                                        backgroundColor: currentCard !== index ? '#FFF' : '#F5F3F280'
                                                    }}
                                                    key={index}
                                                >
                                                    {
                                                        currentCard !== index ?
                                                            <div className='general-cards-single-row' style={{
                                                                width: '100%', justifyContent: 'space-between'
                                                            }}>
                                                                {
                                                                    card.link ? <span>{card.type}</span>
                                                                        : <span>{card.bank} {card.name}</span>
                                                                }

                                                                <Button
                                                                    type='ghost'
                                                                    onClick={() => setCurrentCard(index)}
                                                                    id={`build-cover-date-buttons`}
                                                                    icon={<IoIosArrowDown size={18} />} />
                                                            </div>

                                                            :
                                                            <>
                                                                <div className='general-cards-single-row' style={{
                                                                    width: '100%', justifyContent: 'space-between',
                                                                    marginBottom: '8px'
                                                                }}>

                                                                    <div className='general-cards-single-row'>
                                                                        <span className='gc-content-label'>
                                                                            {
                                                                                card.link ?
                                                                                    "Tarjeta de regalo"
                                                                                    : "Cuenta bancaria"
                                                                            }
                                                                        </span>

                                                                        <Switch
                                                                            style={{
                                                                            }}
                                                                            size='small'
                                                                            onChange={() => toggleCardState(index)}
                                                                            checked={card.link}
                                                                        />
                                                                    </div>

                                                                    <div className='general-cards-single-row'>

                                                                        <Button
                                                                            type='ghost'
                                                                            onClick={() => setCurrentCard(null)}
                                                                            id={`build-cover-date-buttons`}
                                                                            style={{ transform: 'rotate(180deg)' }}
                                                                            icon={<IoIosArrowDown size={18} />} />

                                                                    </div>



                                                                </div>


                                                                {
                                                                    card.link ?

                                                                        <div className='build-generals-simple-column' style={{
                                                                            gap: '12px'
                                                                        }}>
                                                                            <Input
                                                                                className='gc-input-text'
                                                                                style={{
                                                                                    // marginBottom: '10px'
                                                                                }}
                                                                                onChange={(e) => changeCardTypeByIndex(index, e.target.value)}
                                                                                value={card.type} />

                                                                            <Input
                                                                                className='gc-input-text'
                                                                                onChange={(e) => changeCardUrlByIndex(index, e.target.value)}
                                                                                value={card.url} />

                                                                            <Button
                                                                                type='ghost'
                                                                                id="build-cover-date-buttons"
                                                                                onClick={() => deleteCardByIndex(index)}
                                                                                icon={<RiDeleteBack2Line size={16} />}
                                                                            >Eliminar</Button>
                                                                        </div>

                                                                        :
                                                                        <div className='build-generals-simple-column' style={{
                                                                            gap: '8px'
                                                                        }}>

                                                                            <div className='general-cards-single-row' style={{
                                                                                width: '100%'
                                                                            }}>
                                                                                <Input
                                                                                    className='gc-input-text'
                                                                                    style={{
                                                                                        flex: 1
                                                                                        // marginBottom: '10px'
                                                                                    }}
                                                                                    onChange={(e) => changeCardBankByIndex(index, e.target.value)}
                                                                                    value={card.bank} />
                                                                                <Input
                                                                                    className='gc-input-text'
                                                                                    style={{
                                                                                        flex: 1
                                                                                        //  marginBottom: '10px'
                                                                                    }}
                                                                                    onChange={(e) => changeCardNameByIndex(index, e.target.value)}
                                                                                    value={card.name} />

                                                                            </div>
                                                                            <Input
                                                                                className='gc-input-text'
                                                                                onChange={(e) => changeCardNumberByIndex(index, e.target.value)}
                                                                                value={card.number} />

                                                                            <Button
                                                                                type='ghost'
                                                                                id="build-cover-date-buttons"
                                                                                onClick={() => deleteCardByIndex(index)}
                                                                                icon={<RiDeleteBack2Line size={16} />}
                                                                            >Eliminar</Button>
                                                                        </div>

                                                                }
                                                            </>
                                                    }





                                                </div>
                                            ))
                                            : <></>
                                    }

                                </div>

                                : <div style={{
                                    width: '100%', height: '300px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>

                        }

                    </div >
                    // <div style={{
                    //     width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                    //     flexDirection: 'column'
                    // }}>
                    //     <Switch
                    //         value={invitation.gifts.active}
                    //         onChange={handleActive} />
                    //     <Input
                    //         onChange={onChangeTitle}
                    //         placeholder={invitation.gifts.title}
                    //         style={{
                    //             width: '300px'
                    //         }} />

                    //     <Input.TextArea
                    //         placeholder={invitation.gifts.description}
                    //         onChange={onChangeDescription}
                    //         autoSize={{ minRows: 5, maxRows: 10 }}
                    //         style={{
                    //             width: '300px'
                    //         }} />


                    //     <Button onClick={addNewCard}>Add card</Button>

                    //     {
                    //         invitation.gifts.cards ?
                    //             invitation.gifts.cards.map((card, index) => (

                    //                 <>
                    //                     <Button key={index} onClick={() => deleteCardByIndex(index)}>Remove card</Button>
                    //                     <Switch
                    //                         onChange={() => toggleCardState(index)}
                    //                         checked={card.link}
                    //                         checkedChildren="Web" unCheckedChildren="Debit card" />

                    //                     {
                    //                         card.link ?
                    //                             <>
                    //                                 <Input
                    //                                     onChange={(e) => changeCardTypeByIndex(index, e.target.value)}
                    //                                     placeholder={card.type} />
                    //                                 <Input
                    //                                     onChange={(e) => changeCardUrlByIndex(index, e.target.value)}
                    //                                     placeholder={card.url} />
                    //                             </>

                    //                             :
                    //                             <>
                    //                                 <Input
                    //                                     onChange={(e) => changeCardBankByIndex(index, e.target.value)}
                    //                                     placeholder={card.bank} />
                    //                                 <Input
                    //                                     onChange={(e) => changeCardNameByIndex(index, e.target.value)}
                    //                                     placeholder={card.name} />
                    //                                 <Input
                    //                                     onChange={(e) => changeCardNumberByIndex(index, e.target.value)}
                    //                                     placeholder={card.number} />
                    //                             </>
                    //                     }
                    //                     {/* <Input key={index}
                    //                         onChange={(e) => editBoardItemByIndex(index, e.target.value)}
                    //                         placeholder={board}
                    //                         style={{
                    //                             width: '300px'
                    //                         }} /> */}
                    //                 </>
                    //             ))
                    //             : <></>
                    //     }

                    // </div>
                    : <></>
            }
        </>
    )
}


// return (
//     <>
//         {
//             invitation ?


//                 <div style={{
//                     width: '92%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
//                     flexDirection: 'column',
//                     border: '1px solid red'
//                 }}>
//                     <span style={{
//                         width: '100%', textAlign: 'left',
//                         fontWeight: 600, marginBottom: '20px', letterSpacing: '0',
//                         fontFamily: ''
//                     }}>Dresscode</span>

//                     <span
//                         style={{
//                             width: '100%', textAlign: 'left', lineHeight: '1.3',
//                             marginBottom: '30px', fontSize: '1.1em', color: '#00000080',
//                             fontFamily: ''
//                         }}
//                     >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus turpis, aliquet id dapibus sed, convallis sit amet tortor. Integer lacinia maximus ex in placerat.</span>


//                     <Row style={{
//                         width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row'
//                     }}>

//                         <Col style={{
//                             width: '40%', display: 'flex', alignItems: 'flex-start',
//                             justifyContent: 'center', flexDirection: 'column',
//                             margin: '7px 0'
//                         }}>
//                             <span style={{
//                                 width: 'auto', fontSize: '1em', fontFamily: '',
//                                 marginLeft: '5px'
//                             }}>Título</span>
//                             <Input
//                                 style={{
//                                     width: '100%', borderRadius: '30px'
//                                 }}
//                                 onChange={onChangeTitle}
//                                 placeholder={invitation.dresscode.title} />
//                         </Col>

//                         < Switch
//                             value={invitation.dresscode.active}
//                             onChange={handleActive} />
//                     </Row>

//                     <Row style={{
//                         width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row'
//                     }}>

//                         <Col style={{
//                             width: '40%', display: 'flex', alignItems: 'flex-start',
//                             justifyContent: 'center', flexDirection: 'column',
//                             margin: '7px 0'
//                         }}>
//                             <span style={{
//                                 width: 'auto', fontSize: '1em', fontFamily: '',
//                                 marginLeft: '5px'
//                             }}>Descripción</span>
//                             <Input.TextArea
//                                 autoSize={{ minRows: 5, maxRows: 10 }}
//                                 style={{
//                                     width: '100%', borderRadius: '15px'
//                                 }}
//                                 placeholder={invitation.dresscode.description}
//                                 onChange={onChangeDescription} />

//                             <span style={{
//                                 width: 'auto', fontSize: '1em', fontFamily: '',
//                                 marginLeft: '5px', marginTop: '10px'
//                             }}>Username</span>
//                             <Input
//                                 style={{
//                                     width: '100%', borderRadius: '30px'
//                                 }}
//                                 onChange={onChnageUsername}
//                                 placeholder={invitation.dresscode.username} />

//                             <Button
//                                 onClick={addItemToBoards}
//                                 icon={<IoMdAdd style={{
//                                     color: '#FFF'
//                                 }} />}
//                                 type='primary' style={{
//                                     borderRadius: '30px', color: '#FFF',
//                                     display: 'flex', alignItems: 'center', justifyContent: 'center',
//                                     marginTop: '20px'
//                                 }}>
//                                 Nuevo Tablero
//                             </Button>

//                             {
//                                 invitation.dresscode.boards ?
//                                     invitation.dresscode.boards.map((board, index) => (
//                                         <div
//                                             key={index}
//                                             style={{
//                                                 width: '100%', borderRadius: '30px',
//                                                 border: '1px solid #D9D9D9', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
//                                                 flexDirection: 'row', position: 'relative',
//                                                 marginTop: '15px'
//                                             }}>
//                                             <Input
//                                                 placeholder={board}
//                                                 onChange={(e) => editBoardItemByIndex(index, e.target.value)}
//                                                 style={{
//                                                     width: '90%', borderRadius: '30px',
//                                                     border: 'none'
//                                                 }} />

//                                             <Button
//                                                 onClick={() => deleteItemFromBoardsByIndex(index)}
//                                                 style={{
//                                                     height: '28px', width: '28px', borderRadius: '50%',
//                                                     backgroundColor: '#F5F5F7', border: 'none',
//                                                     color: '#878787', 
//                                                     display: 'flex', alignItems: 'center', justifyContent: 'center',
//                                                     position: 'absolute', right: '2px'
//                                                 }}
//                                                 icon={<LuMinusCircle size={12} />}
//                                             />
//                                         </div>
//                                     ))
//                                     : <></>
//                             }

//                         </Col>

//                         <Col style={{
//                             width: '57%', display: 'flex', alignItems: 'flex-start',
//                             justifyContent: 'center', flexDirection: 'column',
//                             marginTop: '-5px'
//                             margin: '7px 0'
//                         }}>
//                             <span style={{
//                                 width: 'auto', fontSize: '1em', fontFamily: '',
//                                 marginLeft: '5px', marginTop: '10px'
//                             }}>¿Cómo funcionan los tableros de pinterest?</span>

//                             <div style={{
//                                 width: '100%', border: '1px solid #e3e3e3',
//                                 height: '380px', borderRadius: '15px'
//                             }}>

//                             </div>

//                         </Col>



//                     </Row>

//                 </div >

//                 : <></>
//         }
//     </>
// )