import { Button, Input, Row, Switch } from 'antd'


import { BiSolidColorFill, BsStars, LuSeparatorHorizontal, TbEyeClosed, greetingsAI, quotesAI } from '../../helpers';
import { appContext } from '../../context';
import { useContext, useEffect, useState } from 'react';



export const BuildQuote = ({ invitation, setInvitation, setSaved, onDesign }) => {

    // const { onDesign } = useContext(appContext)
    const [onButton, setOnButton] = useState(false)
    const [onGeneration, setOnGeneration] = useState(false)
    const [quoteValue, setQuoteValue] = useState(null)
    const [textAppear, setTextAppear] = useState(false)

    const handleGenerating = () => {

        let local_quote = quotesAI.wedding[Math.floor(Math.random() * 9)]

        setQuoteValue('Generando ...');

        setTimeout(() => {
            setQuoteValue(local_quote)
        }, 4500);

        setOnGeneration(true);

        setTimeout(() => {
            setInvitation(prevInvitation => ({
                ...prevInvitation,
                quote: {
                    ...prevInvitation.quote,
                    description: local_quote,
                },
            }));
            setSaved(false);
            setOnGeneration(false);
        }, 5000);
    };

    const onChangeQuote = (e) => {

        setQuoteValue(e.target.value)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            quote: {
                ...prevInvitation.quote,
                description: e ? e.target.value : prevInvitation.quote.description,
            },
        }));
        setSaved(false)
    }

    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            quote: {
                ...prevInvitation.quote,
                active: e,
            },
        }));
        setSaved(false)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            quote: {
                ...prevInvitation.quote,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            quote: {
                ...prevInvitation.quote,
                separator: e,
            },
        }));
        setSaved(false)
    }

    useEffect(() => {
        setQuoteValue(invitation.quote.description)
    }, [])

    useEffect(() => {

        if (onButton) {
            setTimeout(() => {
                setTextAppear(onButton)
            }, 350);

        } else {
            setTextAppear(onButton)
        }

    }, [onButton])


    return (
        <>
            {
                invitation ?
                    <div className='scroll-item generals-main-container'>
                        <div className='build-component-elements'>

                            <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                                <div className='general-cards-single-row'>
                                    <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                                        style={{
                                            width: 'auto',
                                        }}
                                    >Cita</span>
                                    <Switch
                                        size='small'
                                        value={invitation.quote.active}
                                        onChange={handleActive} />
                                </div>


                                <div className='general-cards-single-row' style={{ gap: '5px' }}>
                                    {
                                        invitation.quote.active && (

                                            <>
                                                <Button
                                                    onMouseEnter={() => setOnButton(true)}
                                                    onMouseLeave={() => setOnButton(false)}
                                                    type='ghost'
                                                    onClick={handleGenerating}
                                                    id={`gc-cta-magic-btn`}
                                                    style={{
                                                        width: '35px',
                                                        transition: 'all 0.4s ease',
                                                        boxShadow: onButton ? '0 0 15px #FFB32D' : '',
                                                        borderRadius: '8px',
                                                        height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                        background: onButton ? 'linear-gradient(to bottom, #FFB32D, #fdc564)' : 'transparent',
                                                        color: onButton ? '#FFF' : '#000',


                                                    }}
                                                    icon={<BsStars size={20} />}>

                                                </Button>

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeSeparator(!invitation.quote.separator)}
                                                    id={`build-cover-date-buttons${invitation.quote.separator ? '--active' : ''}`}
                                                    icon={<LuSeparatorHorizontal size={18} />} />

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeBackground(!invitation.quote.background)}
                                                    id={`build-cover-date-buttons${invitation.quote.background ? '--active' : ''}`}
                                                    icon={<BiSolidColorFill size={18} />} />
                                            </>
                                        )
                                    }
                                </div>

                            </div>

                            {
                                invitation.quote.active ?

                                    <Input.TextArea
                                        // value={invitation.quote.description}
                                        value={quoteValue}
                                        autoSize={{ minRows: 20, maxRows: 30 }}
                                        onChange={onChangeQuote}
                                        className={`gc-input-text ${onGeneration ? 'magic-effect' : ''}`}
                                        style={{
                                            width: '100%', borderRadius: '12px',
                                            padding: '10px 15px', marginTop: '15px'
                                        }} />
                                    : <div />

                            }
                        </div>

                        {/* <Row
                            style={{
                                alignItems: 'flex-start',
                                display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column',
                                width: '100%'
                            }}>

                            <Row style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row',
                                width: '100%', marginBottom: '10px'
                            }}>


                                {
                                    invitation.quote.active && (
                                        <>

                                            {
                                                invitation.label === 'wedding' && (
                                                    <Button
                                                        onMouseEnter={() => setOnButton(true)}
                                                        onMouseLeave={() => setOnButton(false)}
                                                        type='ghost'
                                                        onClick={handleGenerating}
                                                        id={`gc-cta-magic-btn`}
                                                        style={{
                                                            width: onButton ? '110px' : '32px',
                                                            transition: 'all 0.4s ease',
                                                            boxShadow: onButton ? '0 0 15px #FFB32D' : '',
                                                            borderRadius: '50px',
                                                            height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                            background: onButton ? 'linear-gradient(to bottom, #FFB32D, #fdc564)' : 'transparent',
                                                            color: onButton ? '#FFF' : '#000',


                                                        }}
                                                        icon={<BsStars size={onButton ? 14 : 22} />}>
                                                        <span style={{
                                                            opacity: textAppear ? 1 : 0,
                                                            display: onButton ? 'flex' : 'none',
                                                            transition: 'all 0.4s ease',
                                                            color: '#FFF'
                                                        }}>Generar</span>
                                                    </Button>
                                                )
                                            }

                                            <Button
                                                type='ghost'
                                                onClick={() => onChangeSeparator(!invitation.quote.separator)}
                                                id={`gc-cta-buttons${invitation.quote.separator ? '--selected' : ''}`}
                                                icon={<LuSeparatorHorizontal size={25} />} />

                                            <Button
                                                type='ghost'
                                                onClick={() => onChangeBackground(!invitation.quote.background)}
                                                id={`gc-cta-buttons${invitation.quote.background ? '--selected' : ''}`}
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                                icon={<BiSolidColorFill size={25} />} />

                                        </>
                                    )
                                }



                                <Switch
                                    value={invitation.quote.active}
                                    onChange={handleActive} />

                            </Row>

                            {
                                invitation.quote.active ?

                                    <Input.TextArea
                                        // value={invitation.quote.description}
                                        value={quoteValue}
                                        autoSize={{ minRows: 10, maxRows: 20 }}
                                        onChange={onChangeQuote}
                                        className={`gc-input-text ${onGeneration ? 'magic-effect' : ''}`}
                                        style={{
                                            width: '100%', borderRadius: '15px',
                                            minWidth: '290px', padding: '10px 15px'
                                        }} />
                                    : <div />

                            }






                        </Row> */}

                        {
                            !invitation.quote.active && (
                                <div style={{
                                    width: '100%', height: '300px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>
                            )
                        }

                    </div>
                    : <></>
            }
        </>
    )
}


