import { Button, Col, Input, Row, Switch } from 'antd'


import { BiSolidColorFill, IoMdAdd, IoClose, LuSeparatorHorizontal, TbEyeClosed, RiDeleteBack2Line } from '../../helpers';
import { CustomButton } from '../../components/CustomButton';
import { useContext } from 'react';
import { appContext } from '../../context';


export const BuildNotices = ({ invitation, setInvitation, setSaved, onDesign }) => {

    // const { onDesign } = useContext(appContext)


    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            notices: {
                ...prevInvitation.notices,
                active: e,
            },
        }));
        setSaved(false)
    }



    const addNewNotice = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            notices: {
                ...prevInvitation.notices,
                notices: [
                    ...prevInvitation.notices.notices,
                    null
                ]
            }
        }));
        setSaved(false)
    }

    const deleteNoticeByIndex = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            notices: {
                ...prevInvitation.notices,
                notices: prevInvitation.notices.notices.filter((notice, i) => i !== index)
            }
        }));
        setSaved(false)
    }

    const editNoticeByIndex = (index, updatedNotice) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            notices: {
                ...prevInvitation.notices,
                notices: prevInvitation.notices.notices.map((notice, i) => {
                    if (i === index) {
                        return updatedNotice;
                    }
                    return notice;
                })
            }
        }));
        setSaved(false)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            notices: {
                ...prevInvitation.notices,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            notices: {
                ...prevInvitation.notices,
                separator: e,
            },
        }));
        setSaved(false)
    }




    return (
        <>
            {
                invitation ?

                    <div className='scroll-item generals-main-container'>
                        <div className='build-component-elements'>
                            <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                                <div className='general-cards-single-row'>
                                    <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                                        style={{
                                            width: 'auto',
                                        }}
                                    >Avisos</span>
                                    <Switch
                                        size='small'
                                        value={invitation.notices.active}
                                        onChange={handleActive} />
                                </div>

                                <div className='general-cards-single-row' style={{ gap: '5px' }}>
                                    {
                                        invitation.notices.active && (

                                            <>
                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeSeparator(!invitation.notices.separator)}
                                                    id={`build-cover-date-buttons${invitation.notices.separator ? '--active' : ''}`}
                                                    icon={<LuSeparatorHorizontal size={18} />} />

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeBackground(!invitation.notices.background)}
                                                    id={`build-cover-date-buttons${invitation.notices.background ? '--active' : ''}`}
                                                    icon={<BiSolidColorFill size={18} />} />
                                            </>
                                        )
                                    }
                                </div>

                            </div>

                            {
                                invitation.notices.active ?
                                    <>
                                        <CustomButton
                                            onClick={addNewNotice}
                                            icon={<IoMdAdd />} text={'Nuevo Aviso'} style={{
                                                margin: '10px 0px'
                                            }} />
                                        <Col style={{
                                            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                            justifyContent: 'flex-start', flexDirection: 'column',
                                            gap: '8px'
                                        }}>
                                            {
                                                invitation.notices.notices ?
                                                    invitation.notices.notices.map((notice, index) => (

                                                        <div
                                                            key={index}
                                                            style={{
                                                                width: '100%', position: 'relative',
                                                                // paddingLeft: '7px'
                                                                // borderRadius: '15px'
                                                            }}>

                                                            <Input.TextArea
                                                                className='gc-input-text'
                                                                style={{ borderRadius: '8px', minWidth: '290px' }}
                                                                autoSize={{ minRows: 5, maxRows: 6 }}
                                                                onChange={(e) => editNoticeByIndex(index, e.target.value)}
                                                                placeholder='Nuevo aviso'
                                                                value={notice} />

                                                            <Button
                                                                type='ghost'
                                                                id="gc-cta-buttons--remove"
                                                                onClick={() => deleteNoticeByIndex(index)}
                                                                icon={<RiDeleteBack2Line size={20} />}
                                                                style={{ position: 'absolute', top: '5px', right: '5px' }}
                                                            />


                                                        </div>
                                                    ))
                                                    : <></>
                                            }
                                        </Col>
                                    </>
                                    : <div style={{
                                        width: '100%', height: '300px',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                                    }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>
                            }
                        </div>

                    </div>

                    : <></>
            }
        </>
    )
}


