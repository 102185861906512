import React, { useContext, useEffect, useRef } from 'react'
import { Col } from 'antd'
import { darker, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { Separador } from '../../components/invitation/Logos'
import '../../styles/modules/module-generals.css'


export const Family = ({ content, familyRef, dev }) => {
    const { MainColor, theme, font, colorPalette } = useContext(appContext)
    // const familyRef = useRef(null)


    return (
        <>
            {content.active && colorPalette ? (

                <>
                    <div className='gm-container' style={{
                        backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                        padding: content.background ? '32px' : '0px 32px'
                    }}>
                        <div
                            className="g-module-info-container"

                        >
                            <span
                                className={!dev ? "g-module-title" : "g-module-title-dev"}
                                style={{ color: colorPalette.accent, fontFamily: font }}
                            >
                                {content.title}
                            </span>

                            {content.personas ? (
                                content.personas.map((persona, index) => (
                                    <div
                                        key={index}
                                        className="g-module-items-single-col"
                                    >
                                        <span
                                            className={!dev ? "g-mdoule-light-text" : "g-mdoule-light-text-dev"}
                                            style={{
                                                fontFamily: font,
                                                color: `${colorPalette.accent}99`
                                            }}
                                        >
                                            {persona.title}
                                        </span>

                                        <span
                                            className={!dev ? "g-mdoule-regular-text" : "g-mdoule-regular-text-dev"}
                                            style={{
                                                fontFamily: font,
                                                color: colorPalette.accent
                                            }}
                                        >
                                            {persona.name}
                                        </span>
                                    </div>
                                ))
                            ) : null}
                        </div>
                    </div>

                    {content.separator &&
                        <Separador MainColor={colorPalette.accent} theme={theme} />
                    }
                </>

            ) : null
            }
        </>
    )
}

