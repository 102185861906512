import { Button, Col, Input, Row, Switch } from 'antd'

import { BiSolidColorFill, IoMdAdd, IoClose, LuSeparatorHorizontal, TbEyeClosed, RiDeleteBack2Line } from '../../helpers';
import { CustomButton } from '../../components/CustomButton';
import { useContext } from 'react';
import { appContext } from '../../context';


export const BuildFamily = ({ invitation, setInvitation, setSaved, onDesign }) => {

    // const { onDesign } = useContext(appContext)

    const onChangeTitle = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                title: e ? e.target.value : prevInvitation.family.title,
            },
        }));
        setSaved(false)
    }

    const changeTitlePersona = (index, newType) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                personas: prevInvitation.family.personas.map((persona, i) => {
                    if (i === index) {
                        return {
                            ...persona,
                            title: newType
                        };
                    }
                    return persona;
                })
            }
        }));
        setSaved(false)
    }

    const changeNamePersona = (index, newType) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                personas: prevInvitation.family.personas.map((persona, i) => {
                    if (i === index) {
                        return {
                            ...persona,
                            name: newType
                        };
                    }
                    return persona;
                })
            }
        }));
        setSaved(false)
    }

    const addNewPersona = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                personas: [
                    ...prevInvitation.family.personas,
                    {
                        title: null,
                        name: null
                    }
                ]
            }
        }));
        setSaved(false)
    }

    const deletePersonaByIndex = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                personas: prevInvitation.family.personas.filter((persona, i) => i !== index)
            }
        }));
        setSaved(false)
    }

    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                active: e,
            },
        }));
        setSaved(false)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                separator: e,
            },
        }));
        setSaved(false)
    }

    return (
        <>
            {
                invitation ?
                    <div className='scroll-item generals-main-container'>

                        <div className='build-component-elements'>
                            <div className='general-cards-single-row' style={{ width: '100%', justifyContent: 'space-between' }}>
                                <div className='general-cards-single-row'>
                                    <span className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                                        style={{
                                            width: 'auto',
                                        }}
                                    >Personas</span>
                                    <Switch
                                        size='small'
                                        value={invitation.family.active}
                                        onChange={handleActive} />
                                </div>


                                <div className='general-cards-single-row' style={{ gap: '5px' }}>
                                    {
                                        invitation.family.active && (

                                            <>

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeSeparator(!invitation.family.separator)}
                                                    id={`build-cover-date-buttons${invitation.family.separator ? '--active' : ''}`}
                                                    icon={<LuSeparatorHorizontal size={18} />} />

                                                <Button
                                                    type='ghost'
                                                    onClick={() => onChangeBackground(!invitation.family.background)}
                                                    id={`build-cover-date-buttons${invitation.family.background ? '--active' : ''}`}
                                                    icon={<BiSolidColorFill size={18} />} />
                                            </>
                                        )
                                    }
                                </div>

                            </div>

                            {
                                invitation.family.active ?
                                    <>
                                        <span className='simple-content-label'>Título</span>

                                        <Input
                                            onChange={onChangeTitle}
                                            value={invitation.family.title}
                                            className='gc-input-text' />
                                        <CustomButton
                                            onClick={addNewPersona}
                                            icon={<IoMdAdd />} text={'Nueva Persona'} style={{
                                                margin: '20px 0 10px 0px'
                                            }} />
                                        {
                                            invitation.family.personas.map((persona, index) => (
                                                <Col
                                                    key={index}
                                                    className='regular-card'
                                                    style={{
                                                        width: !onDesign && '100%'
                                                    }}>

                                                    <Button
                                                        // id='gc-cta-secondary-button'
                                                        type='ghost'
                                                        id="gc-cta-buttons--remove"
                                                        onClick={() => deletePersonaByIndex(index)}
                                                        icon={<RiDeleteBack2Line size={16} />}
                                                        style={{ position: 'absolute', top: '5px', right: '5px' }}
                                                    />

                                                    <span className='simple-content-label'>Título</span>

                                                    <Input
                                                        onChange={(e) => changeTitlePersona(index, e.target.value)}
                                                        value={persona.title}
                                                        placeholder='Título de la persona'
                                                        className='gc-input-text' />

                                                    <span className='simple-content-label'>Nombre</span>

                                                    <Input
                                                        // onChange={onChangeTitle}
                                                        onChange={(e) => changeNamePersona(index, e.target.value)}
                                                        value={persona.name}
                                                        placeholder='Nombre de la persona'
                                                        className='gc-input-text' />
                                                </Col>
                                            ))
                                        }
                                    </>
                                    : <div style={{
                                        width: '100%', height: '300px',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                                    }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>
                            }



                        </div>


                    </div >
                    : <></>
            }
        </>
    )
}


