import React, { useContext, useEffect, useRef } from 'react'
import { darker, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { Separador } from '../../components/invitation/Logos'
import '../../styles/modules/module-generals.css'


export const Quote = ({ content, quoteRef, dev }) => {


    const { MainColor, theme, font, colorPalette } = useContext(appContext)
    // const quoteRef = useRef(null)

    const maxLength = 90; // Longitud máxima del borderRadius
    const length = content.description.length; // Longitud actual del texto

    // Calcular el valor del borderRadius en función de la longitud del texto
    const borderRadius = length <= maxLength ? '50%' : `${Math.max(11, maxLength - length)}px`;




    return (
        <>
            {content.active && colorPalette ? (
                <>
                    <div className='gm-container' style={{
                        backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                        // padding: '12px 36px'

                    }}>
                        <div
                            className={`g-module-info-container ${content.background ? 'quote-element-full' : 'quote-element-parcial'}`}
                            style={{
                                borderRadius: content.background ? '0px' : borderRadius,
                                backgroundColor: content.background && colorPalette.secondary,
                            }}
                        >
                            <span
                                className={!dev ? "g-mdoule-regular-text" : "g-mdoule-regular-text-dev"}
                                style={{
                                    color: colorPalette.accent,
                                    fontFamily: font, width: '60%'
                                }}
                            >
                                {content.description}
                            </span>
                        </div>

                    </div>

                    {content.separator &&
                        <Separador MainColor={colorPalette.accent} theme={theme} />
                    }
                </>
            ) : null}
        </>
    );
}
