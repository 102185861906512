import React, { useEffect, useState } from 'react'
import { Button, Col } from 'antd'
import { CustomCard, } from './Steps'
import { buttonsColorText, darker, extratImageID, lighter } from '../../helpers/functions'
import { FaChurch } from "react-icons/fa";
import { icons } from '../../helpers/images';
import { Ceremony, CeremonyIcon, DanceIcon, DinnerIcon, Reception, ReceptionIcon } from './Logos';
import { LuBadgeHelp } from 'react-icons/lu';
import { iconsItinerary } from '../../helpers';


export const Card = ({
    steps, MainColor, theme, font, dev, colorPalette, background
}) => {

    const [stepsItems, setStepsItems] = useState(steps)

    const handleSelectedCard = (id) => {
        const updatedCards = stepsItems.map((card) => card.id === id ? { ...card, active: !card.active } : card)
        setStepsItems(updatedCards)
    }


    useEffect(() => {
        setStepsItems(steps)
    }, [steps])


    const renderIcon = (index) => {
        // console.log(index)
        const icon = iconsItinerary.find(icon => icon.index === index);
        if (icon) {
            const IconComponent = icon.value;
            return <IconComponent size={35} style={{ color: colorPalette.accent }} />;
        }
        return <LuBadgeHelp size={35} style={{ color: colorPalette.accent }} />;
    };

    // const background = `linear-gradient(to bottom, ${(colorPalette.secondary)}80 0%, ${(colorPalette.secondary)} 100%)`




    return (
        <>
            {
                stepsItems.map((item, index) => (
                    <div
                        key={index}
                        className={`${dev ? 'card-container-dev' : 'card-container'} ${item.active ? 'card-container-active' : ''}`}
                        style={{
                            background: background ? colorPalette.primary : colorPalette.secondary,
                            minHeight: item.active ? 'auto' : '110px',
                            justifyContent: item.active ? 'center' : 'flex-start',
                            flexDirection: item.active ? 'column' : 'row',
                            border: 'none',
                            position: 'relative',
                            gap: !item.active && '8px',
                            padding: item.active && '12px'
                        }}
                    >
                        {item.active ? (
                            <CustomCard dev={dev} onClose={handleSelectedCard} item={item} MainColor={MainColor} theme={theme} font={font} colorPalette={colorPalette} />
                        ) : (
                            <>
                                <div
                                    className="card-icon-wrapper"

                                >
                                    <div
                                        className="card-icon"
                                        style={{
                                            backgroundColor: background ? colorPalette.secondary : colorPalette.primary,
                                            border: 'none',
                                        }}
                                    >
                                        {item.image ? renderIcon(parseInt(item.image)) : <LuBadgeHelp size={32} style={{ color: colorPalette.accent }} />}
                                    </div>
                                </div>
                                <div
                                    className="card-content"
                                    onClick={() => handleSelectedCard(item.id)}
                                    style={{
                                        color: colorPalette.accent
                                    }}
                                >
                                    <span className={!dev ? "g-mdoule-regular-text" : "g-mdoule-regular-text-dev"} style={{ fontFamily: font, lineHeight: 1, fontSize: '16px' }}><b>{item.name}</b></span>
                                    <span className={!dev ? "g-mdoule-light-text" : "g-mdoule-light-text-dev"} style={{ fontFamily: font, opacity: '0.8' }}>{item.time}</span>
                                    <span className={!dev ? "g-mdoule-regular-text" : "g-mdoule-regular-text-dev"} style={{ fontFamily: font, fontSize: '13px' }}>{item.subname}</span>


                                </div>

                                {
                                    (item.subitems || item.playlist || item.address) ? (
                                        <Button
                                            onClick={() => handleSelectedCard(item.id)}
                                            style={{
                                                fontFamily: font,
                                                background: colorPalette.buttons,
                                                color: buttonsColorText(colorPalette.buttons),
                                                borderRadius: '50px',
                                                border: 'none',
                                                height: '30px',
                                                flex: 1,
                                                fontSize: '12px',
                                                fontWeight: 600
                                            }}
                                        >
                                            Abrir
                                        </Button>
                                    ) : null
                                }


                            </>
                        )}
                    </div>
                ))
            }
        </>
    )
}
