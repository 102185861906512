import React, { useContext, useEffect, useRef } from 'react'
import { GalleryItems } from '../../components/invitation/GalleryItems'
import { darker, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { Separador } from '../../components/invitation/Logos'
import '../../styles/modules/gallery.css'
import '../../styles/modules/module-generals.css'


export const Gallery = ({ content, galleryRef, dev }) => {

    const { MainColor, theme, font, colorPalette } = useContext(appContext)
    // const galleryRef = useRef(null)


    return (
        <>
            {content.active && colorPalette ? (
                <>
                    <div className='gm-container'
                        style={{
                            backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                            padding: content.background ? '32px' : '0px 32px'
                        }}
                    >
                        <div ref={galleryRef} id='gallery' className="g-module-info-container">
                            <span className={!dev ? "g-module-title" : "g-module-title-dev"} style={{ fontFamily: font, color: colorPalette.accent }}>
                                GALERIA
                            </span>
                            <div className='gallery-scroll-invitation'>
                                <GalleryItems MainColor={MainColor} content={content} dev={dev} />
                            </div>
                        </div>


                    </div>
                    {content.separator &&
                        <Separador MainColor={colorPalette.accent} theme={theme} />
                    }
                </>

            ) : null}
        </>
    )
}
