import { Button, Image, Row } from 'antd'
import { buttonsColorText, convertirEnlaceDrive, darker, extratImageID, lighter } from '../../helpers/functions'
import { dynamicMap, simpleaddress } from '../../helpers/addressurl'
import { Link } from 'react-router-dom'
import { ForecastWeather, SimpleWeather } from './Weather'
import SpotifyWidget from './SpotifyWidget'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MapComponent } from './MapComponent'

const img = "1LgcQQ-_qnwDeAsDHuLmHsByDIKYESq2P"

const direccion = {
    direccion: 'Privada America Latina',
    numero: '7920',
    colonia: 'CTM',
    codigoPostal: '31130',
    ciudad: 'Chihuahua',
    estado: 'Chihuahua',
    pais: 'Mexico'
}


export const CustomCard = ({ dev, onClose, item, MainColor, theme, font, colorPalette }) => {


    return (
        <div className="custom-card-container">
            <Button
                onClick={() => onClose(item.id)}
                type='ghost'
                icon={<IoIosArrowUp size={20} style={{ color: colorPalette.accent }} />}
                className="custom-card-button"
            />

            <span className={dev ? "custom-card-title-dev" : "custom-card-title"} style={{ fontFamily: font, color: colorPalette.accent }}>
                <b>{item.name}</b>
            </span>

            <span className={dev ? "custom-card-time-dev" : "custom-card-time"} style={{ fontFamily: font, color: colorPalette.accent }}>
                {item.time}
            </span>

            <span className={dev ? "custom-card-subname-dev" : "custom-card-subname"} style={{ fontFamily: font, color: colorPalette.accent }}>
                {item.subname}
            </span>

            {item.address && (
                <span className={dev ? "custom-card-address-dev" : "custom-card-address"} style={{ fontFamily: font, color: `${colorPalette.accent}80` }}>
                    {`${item.address.calle}, ${item.address.numero}, ${item.address.colonia}, ${item.address.CP}, ${item.address.ciudad}, ${item.address.estado}`}
                </span>
            )}

            {item.subitems && (
                <div className="custom-card-subitems">
                    {item.subitems.map((subitem) => (
                        <div key={subitem.name} className="custom-card-subitem">
                            <div className="custom-card-subitem-bullet" style={{ backgroundColor: colorPalette.accent }} />

                            <span className="custom-card-subitem-title" style={{ fontFamily: font, color: colorPalette.accent }}>
                                {subitem.name}
                            </span>
                            <span className="custom-card-subitem-time" style={{ fontFamily: font, color: colorPalette.accent }}>
                                {subitem.time}
                            </span>
                            <span className="custom-card-subitem-description" style={{ fontFamily: font, color: `${colorPalette.accent}80` }}>
                                {subitem.description}
                            </span>
                        </div>
                    ))}
                </div>
            )}

            {item.address && (
                <>
                    <Row className={`custom-card-row ${item.subitems ? 'custom-card-row-column' : ''}`}>

                        {
                            item.address.calle && item.address.numero && item.address.colonia && item.address.CP && item.address.ciudad && item.address.estado && (

                                <>
                                    <div className="custom-card-weather" style={{ height: item.subitems ? '80px' : '97px' }}>
                                        {item.address.CP && (
                                            <ForecastWeather cp={item.address.CP} MainColor={MainColor} theme={theme} font={font} colorPalette={colorPalette} />
                                        )}
                                    </div>

                                    <div className={dev ? "custom-card-map-dev" : "custom-card-map"} style={{ backgroundColor: colorPalette.secondary }}>
                                        {item.address.calle && item.address.numero && item.address.colonia && item.address.CP && item.address.ciudad && item.address.estado && (
                                            <Image
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                src={simpleaddress(item.address.calle, item.address.numero, item.address.colonia, item.address.CP, item.address.ciudad, item.address.estado)}
                                            />
                                        )}
                                    </div>

                                </>


                            )}


                    </Row>
                    {item.address.url && (
                        <Link to={item.address.url} target='_blank' className="custom-card-link">
                            <Button className={dev ? "custom-card-link-button-dev" : "custom-card-link-button"} style={{ fontFamily: font, background: colorPalette.buttons, color: buttonsColorText(colorPalette.buttons) }}>
                                ¿Cómo llegar?
                            </Button>
                        </Link>
                    )}
                </>
            )}

            {item.playlist && <SpotifyWidget url={item.playlist} />}
        </div>
    )
}

